<template>
  <div class="admin-orders-listing">
    <title-bar title="Stock" submitBtn submitBtnTitle="Confirm changes" @submitPressed="saveChanges" />
     <b-tabs
      pills
      class="order-listing-tabs"
      v-model="tabIndex"
      @input="loadStock"
    >
      <b-tab v-for="(tab, tabIndex) of tabs" :key="tabIndex" :title="tab.title" href="#new"></b-tab>
    </b-tabs>
    <stock-listing :stock-type="stockType" :car="car" :car-sizes="carSizes" :warehouse="warehouse" @stockUpdate="addToCart" ref="stockList" />
  </div>
</template>

<script>

import { BTabs } from 'bootstrap-vue'
import TitleBar from '@/components/TitleBar.vue'

import Toast from '@/components/Toast.vue'
import 'vue-good-table/dist/vue-good-table.css'
import StockListing from '@/views/Admin/Stock/components/StockListing.vue'

export default {
  name: 'AdminStockListing',
  components: {
    TitleBar,
    BTabs,
    StockListing
  },
  data() {
    return {
      search: '',
      categories: [],
      tabIndex: 0,
      tabs: [
        {
          title: 'All',
          car: '',
          warehouse: '',
          type: 'all'
        },
      ],
      stockType: 'all',
      car: '',
      carSizes: [],
      warehouse: '',
      cart: [],
      columns: [
        {

          field: 'name',
          sortable: false,
        },
        {
          field: 'price',
          type: 'decimal',
          sortable: false,
        },
        {

          field: 'inventoryQuantity',
          type: 'number',
          sortable: false,
        },
        {
          field: 'stock_update',
          type: 'number',
          sortable: false,
          width: '130px',
        },
      ],
      rows: [

      ],
    }
  },
  created() {
    this.loadTabs()
  },
  methods: {
    loadTabs() {
      this.$Warehouses.getCollection({}, 'warehouse_list').then(locResponse => {
        locResponse.data['hydra:member'].forEach(element => {
          this.tabs.push({
            title: element.name,
            warehouse: element['@id'],
            car: '',
            type: 'warehouse'
          })
        })
        this.$Cars.getCollection({}, 'car_list').then(carResponse => {
          carResponse.data['hydra:member'].forEach(element => {
            this.tabs.push({
              title: element.name,
              warehouse: '',
              car: element['@id'],
              type: 'car',
              size: element.size
            })
            this.carSizes[element['@id']] = element.size
          })
        })
      })
    },
    loadStock() {
      this.cart = []
      this.stockType = this.tabs[this.tabIndex].type
      this.car = this.tabs[this.tabIndex].car
      this.warehouse = this.tabs[this.tabIndex].warehouse
    },
    addToCart(newProduct) {
      let updated = false
      this.cart.forEach(product => {
        if(product.id === newProduct.id) {
          product.stock_update = newProduct.stock_update
          updated = true
        }
      })
      if(!updated) {
        this.cart.push(newProduct)
      }
    },
    updateInventory(id, available) {
      const body = {
        available
      }
      return this.$Inventories.updateResource({ id, body })
        .then()
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(this.$toast, Toast, 'Error', error.response.data['hydra:description'], 'warning')
          }
        })
    },
    saveChanges() {
      const promises = []
      this.cart.forEach(product => {
        if(this.stockType === 'car' || this.stockType === 'warehouse') {
          product.inventories.forEach(inventory => {
            if(product.stock_update !== inventory.available && this[this.stockType] === inventory[this.stockType]) {
              promises.push(this.updateInventory(inventory.id, product.stock_update))
            }
          })
        }
      })
      Promise.all(promises)
        .then(results => {
          if (results.length) {
            this.$helper.showToast(this.$toast, Toast, 'Changes saved', '', 'success')
            this.cart = []
            this.$refs.stockList.loadProducts()
          }
        })
    }
  },
}
</script>
